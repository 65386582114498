import { createAction, handleActions } from "redux-actions";

const SET = "[ACCOUNT]set";
const USERLOGIN = "[ACCOUNT]userlogin";
const RESET = "[ACCOUNT]reset";
const LOGOUT = "[ACCOUNT]logout";

export const userloggin = createAction(USERLOGIN);
export const set = createAction(SET);
export const reset = createAction(RESET);
export const logout = createAction(LOGOUT);

// 초기값.
const init = {
	loggedIn: false,
	accessToken: undefined,
	userId: undefined,
	reduxState: "none",
};

export const account = handleActions(
	{
		[USERLOGIN]: (state, action) => {
			return Object.assign({}, state, action.payload);
		},
		[SET]: (state, action) => {
			return Object.assign({}, state, action.payload);
		},
		[RESET]: (state, action) => {
			return init;
		},
		[LOGOUT]: (state, action) => {
			return { loggedIn: false, accessToken: undefined, userId: undefined, reduxState: "done" };
		},
	},
	init
);
