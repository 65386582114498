import { createAction, handleActions } from "redux-actions";

const SET = "[SERVICEDETAIL]setServiceId";
const RESET = "[SERVICEDETAIL]resetServiceId";
const GETSERVICELIST = "[SERVICEDETAIL]getServiceList";
const GETSERVICEAUTHORITYLEVEL = "[SERVICEDETAIL]getServiceAuthorityLevel"


export const setServiceId = createAction(SET);
export const resetServiceId = createAction(RESET);
export const getServiceList = createAction(GETSERVICELIST);
export const getServiceAuthorityLevel = createAction(GETSERVICEAUTHORITYLEVEL);

const init = {
	serviceId: "0001_medip_default",
	serviceAuthorityLevelId: undefined,
	reduxState: "none",
  serviceList: undefined,
  serviceAuthorityLevel: undefined,
};

export const serviceDetail = handleActions(
	{
		[SET]: (state, action) => {
			return Object.assign({}, state, action.payload);
		},
		[RESET]: (state, action) => {
			return init;
		},
		[GETSERVICELIST]: (state, action) => {
			return Object.assign({}, state, { serviceList: action.payload });
    },
    [GETSERVICEAUTHORITYLEVEL]: (state, action) => {
			return Object.assign({}, state, { serviceAuthorityLevel: action.payload });
    },
	},
	init
);
