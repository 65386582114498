import { createStore, /*applyMiddleware*/ } from 'redux';
import reducers from './reducers';
// import { composeWithDevTools } from 'redux-devtools-extension';
// import redux_async from '../../lib/redux_async'

// 크롬 확장 기능중에 redux devTools가 있어요.
// 설치하시면 리덕스 내부 내용의 변경 과정이 보여요.
const devTools = window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();
// const store = createStore(reducers, composeWithDevTools(applyMiddleware(redux_async.ex({ a : 'ddsf'}))));
const store = createStore(reducers, devTools);

export default store;
