import React, { Component } from "react";
import { Provider } from "react-redux";
import store from "./service/redux/store";
import "./App.css";
import "./Base.css";
import Router from "./Routes/Router";
import Header from "./Components/Header";

class App extends Component {
  render() {
    console.log(window.location.href);
    return (
      <div className="App">
        <Provider store={store}>
          <Header />
          <Router />
        </Provider>
      </div>
    );
  }
}

export default App;
