/**
 * 라우터(client-Side Routing)
 * 각 경로(url)로 접근했을 시, 해당 컴포넌트 렌더링.
 */
import React, { Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import { connect } from 'react-redux';

/**
 * React lazy사용 해당 경로로 갈 때에만 js파일 로드
 * 
 */
const Intro = React.lazy(() => import("../Components/Intro"));
const Login = React.lazy(() => import("../Components/Login"));
const Service = React.lazy(() => import("../Components/ServiceList"));
const User = React.lazy(() => import("../Components/UserList"));
const UserRegist = React.lazy(() => import("../Components/UserRegist"));
const UserDetailInfo = React.lazy(() => import("../Components/UserDetailInfo"));
const Register = React.lazy(() => import("../Components/Register"));
const UserLists = React.lazy(() => import("../Components/UserLists"));
const UserUpdateInfo = React.lazy(() => import("../Components/UserUpdateInfo"));
const UserResetPassword = React.lazy(() =>
  import("../Components/UserResetPassword")
);
const UserDelete = React.lazy(() => import("../Components/UserDelete"));
const UserServiceList = React.lazy(() =>
  import("../Components/UserServiceList")
);
const UserServiceRegist = React.lazy(() =>
  import("../Components/UserServiceRegist")
);
const UserAuthLevel = React.lazy(() => import("../Components/UserAuthLevel"));
const UserFuncLevel = React.lazy(() => import("../Components/UserFuncLevel"));


const Router = ({account}) => {
  return (
    <div className="Router">
      <Suspense fallback={<div>로딩중</div>}>
        <Switch>
          <Route path="/" component={Intro} exact />
          <Route path="/login" component={Login} />
          <Route path="/register" component={Register} />
          <Route path="/service" component={Service} />
          <Route path="/user" component={User} />
          <Route path="/userDetailInfo" component={UserDetailInfo} />
          {/*<Route path="/userDetailInfo" render={()=><UserDetailInfo accessToken={account.accessToken}/>} />*/}
          <Route path="/userRegist" component={UserRegist} />
          <Route path="/userLists" component={UserLists} />
          <Route path="/userUpdateInfo" component={UserUpdateInfo} />
          <Route path="/UserResetPassword" component={UserResetPassword} />
          <Route path="/UserDelete" component={UserDelete} />
          <Route path="/userServiceList" component={UserServiceList} />
          <Route path="/userServiceRegist" component={UserServiceRegist} />
          <Route path="/userAuthLevel" component={UserAuthLevel} />
          <Route path="/userFuncLevel" component={UserFuncLevel} />
          <Route render={() => <div>일치하는 뷰 페이지가 없습니다.</div>} />
        </Switch>
      </Suspense>
    </div>
  );
};


const mapStateToProps = (state) => {
  return {
    account: state.account,
  };
};

export default connect(mapStateToProps)(Router);
