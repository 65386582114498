import { createAction, handleActions } from 'redux-actions';

const SET = '[USERLISTUPDATE]setUserListUpdate';
const RESET = '[USERLISTUPDATE]resetUserListUpdate';

export const setUserListUpdate = createAction(SET);
export const resetUserListUpdate = createAction(RESET);

// 초기값.
const init = {
  userName: undefined,
  userNum: undefined,
  userPw: undefined,
  userEmail: undefined,
  nationCode: undefined,
  institude: undefined,
  reduxState: 'none',
};

export const reducer_userListUpdate = handleActions(
  {
    [SET]: (state, action) => {
      return Object.assign({}, state, action.payload);
    },
    [RESET]: (state, action) => {
      return init;
    },
  },
  init
);
