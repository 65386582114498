import { createAction, handleActions } from "redux-actions";

const SETUSERSEQ = "[USERDETAIL]setUserSeq";
const SETPREVUSERSEQ = "[USERDETAIL]setPrevUserSeq";
const SETUSERDETAILINFO = "[USERDETAIL]setUserDetailInfo";
const GETCOUNTRYCODE = "[USERDETAIL]getCountryCode";
const GETUSERSERVICELIST = "[USERDETAIL]getUserServiceList";
const RESETUSERDETAILINFO = "[USERDETAIL]resetUserSeq";
const RESETUSERSERVICELIST = "[USERDETAIL]resetUserServiceList";

export const setUserSeq = createAction(SETUSERSEQ);
export const setPrevUserSeq = createAction(SETPREVUSERSEQ);
export const setUserDetailInfo = createAction(SETUSERDETAILINFO);
export const getCountryCode = createAction(GETCOUNTRYCODE);
export const resetUserDetailInfo = createAction(RESETUSERDETAILINFO);

export const getUserServiceList = createAction(GETUSERSERVICELIST);
export const resetUserServiceList = createAction(RESETUSERSERVICELIST);

const init = {
	prevUserSeq: undefined,
	userSeq: undefined,
	view: undefined,
	reduxState: "none",
	userInfo: undefined,
	countryCode: undefined,
	userService: undefined,
};

export const userDetail = handleActions(
	{
		[SETUSERSEQ]: (state, action) => {
			return Object.assign({}, state, { userSeq: action.payload });
		},
		[SETPREVUSERSEQ]: (state, action) => {
			return Object.assign({}, state, { prevUserSeq: action.payload });
		},
		[SETUSERDETAILINFO]: (state, action) => {
			return Object.assign({}, state, { userInfo: action.payload });
		},
		[GETCOUNTRYCODE]: (state, action) => {
			return Object.assign({}, state, { countryCode: action.payload });
		},
		[RESETUSERDETAILINFO]: (state, action) => {
			return Object.assign({}, state, { userInfo: undefined});
		},
		[GETUSERSERVICELIST]: (state, action) => {
			return Object.assign({}, state, { userService: action.payload});
		},
		[RESETUSERSERVICELIST]: (state, action) => {
			return Object.assign({}, state, { userService: undefined});
		},
	},
	init
);
