/**헤더 */
import React, { Component } from "react";
import { userloggin, logout } from "../service/redux/reducers/account";
import { getCountryCode } from "../service/redux/reducers/userDetail";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

class Header extends Component {
	state = {
		userEmail: "로그인 필요",
		userPw: "",
	};

	shouldComponentUpdate(nextProps, nextState) {
		return !this.props.account.loggedIn;
	}

	componentDidMount() {
		if (localStorage.medicalip !== undefined) {
			const accessToken = JSON.parse(localStorage.medicalip).accessToken;

			if (accessToken !== undefined && accessToken !== "") {
				this.props.userloggin({
					loggedIn: true,
					accessToken: JSON.parse(localStorage.medicalip).accessToken,
					userId: JSON.parse(localStorage.medicalip).userEmail,
				});
				this.props.history.push("/userLists");
			}
		}
		this.getCountryCode();
	}

	componentDidUpdate() {}

	getCountryCode = () => {
		console.log('국가코드 로드됨');
		fetch("/user/getNationCodeList")
			.then((res) => res.json())
			.then((data) => {
				this.props.getCountryCode({
					nationCode: data.data,
				})
			});
	}

	logOut = (e) => {
		alert("로그아웃 되었습니다.");

		this.props.userLogout();
		localStorage.removeItem("medicalip");
		this.props.history.push("/login");
	};

	render() {
		let accountId = this.props.account.userId;
		console.log("header render 실행");
		return (
			<div id="header">
				{/*JSON.parse(localGet).localId*/}
				<div className="admin_header">
					<div className="admin_header_inner">
						<a href="/" className="admin_header_logo" title=""></a>
						<div className={"admin_myId_wrap " + (this.props.account.loggedIn ? "logIn" : "logOut")}>
							<p>
								<strong>{accountId}</strong>님 환영합니다.
							</p>
							{
								//<a href="/UserUpdateInfo" onClick={this.modify} className="admin_modify">개인정보수정</a>
							}
							<button onClick={this.logOut} className="admin_logOut">
								로그아웃
							</button>
							{/*<a href="/" onClick={this.logOut} className="admin_logOut"><span>로그아웃</span></a>*/}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return { account: state.account, userDetail: state.userDetail };
};

const mapDispatchToProps = (dispatch) => {
	return {
		userloggin: (loginInfo) => {
			dispatch(userloggin(loginInfo));
		},
		getCountryCode: (countryCodes) => {
			dispatch(getCountryCode(countryCodes));
		},
		userLogout: (logoutInfo) => {
			dispatch(logout(logoutInfo));
		},
	};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
